@font-face {
  font-family:"Helvetica";
  src: url("./scss/Helvetica.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica", "Helvetica Neue", Arial, sans-serif;
  color: #465260;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Lato-font {
  font-family: 'Lato', sans-serif;
}