.appLoaderWrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
}

.appLoading {
    position: absolute;
    top: 50%;
    left: 50%;  
    transform: translate(-50%, -50%);
    background: #222952;
    padding: 30px;
    border-radius: 3px;
    color: #fff;
    text-align: center;
}
.hmiLoadingSpinner {
    -webkit-animation: hmiLoadingSpinner .6s infinite linear;
    animation: hmiLoadingSpinner .6s infinite linear;
    border: 2px solid #d9d9d9;
    border-radius: 50%;
    border-top-color: #0079a5;
    display: inline-block;
    height: 32px;
    width: 32px;
}

  @keyframes hmiLoadingSpinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
  }

  .blurelement {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
  }


  .lineLoader {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 2px;
    bottom: 0;
  }

  .lineLoader:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background-color: #ffc000;
    -webkit-animation: HmiLineLoading 1000ms linear;
   animation: HmiLineLoading 1000ms linear;
   -webkit-animation-iteration-count: infinite;
   animation-iteration-count: infinite;
   top: 0;
    
}

@keyframes HmiLineLoading {
  from {left: -200px; width: 30%;}
  50% {width: 30%;}
  70% {width: 70%;}
  80% { left: 50%;}
  95% {left: 120%;}
  to {left: 100%;}
}
