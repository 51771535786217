@import "./scss/variables/global";
@import "./scss/mixins";

.App {
    background: #e2e3e6;
    min-height: 100vh;
}
.sprite-bck.log-out {
    width: 50px;
    height: 42px;
    background-position: -263px -10px;
    display: inline-block;
}

.page-content-wrapper {
    .main-panel {
        background-color: #fff;
        padding-top: 20px;
        padding-left: 25px;
        padding-right: 25px;
        width: 100%;
        position: relative;
        margin-bottom: 3rem;       
        h1 {
            font-size: 24px;
            color: $theme-color;
            font-weight: 600;
            margin-bottom: 0.5rem;
        }
        .nav {
            width: 100%;
            li {
                background-color: $secondary-color;
                color: $theme-color;
                padding: 3px 8px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                font-size: 22px;
                color: #222952;
                font-weight: 600;              
            }
        }
    }
}

.container {
    margin-top: 10px;
   
    &.border {
      border: 1px solid #ddddde;
      border-radius: 4px;
      padding: 15px;
      box-shadow: -1px 0px 2px #dfe0e6;
      background: #fbfbfb;
    }
    .form-group {
        flex: 0 0 25%;
    }
}

.form-control, .custom-select { 
    height: calc(2rem + 2px);
}

.pointer {
    cursor: pointer;
}

//// Form SCSS
.form-invalid input:invalid, .form-invalid textarea:invalid,  .form-invalid select:invalid {
    border: 1px solid red;
}

//Table 
table tr:nth-child(even) {
    background: whitesmoke;

}

.table .active {
    background-color: #bbbed0;
    color: #fff;
}

.table thead tr {
    background: #646a92;
    color: #fff9f9;
}

.table thead th {
   font-weight: normal;
}

.loader-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
}

.loader-wrapper div {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.custom-ui {
    text-align: center;
    width: 500px;
    padding: 40px;
    background: #fff;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    color: #333;
}

.custom-ui > h1 {
    margin-top: 0;
}

.custom-ui > button {
    width: 160px;
    padding: 10px;
    border: 1px solid #fff;
    margin: 10px;
    cursor: pointer;        
    font-size: 14px;
    color: #fff;
    background-color: #6c757d;
    border:1px solid #6c757d;
    border-radius: .25em;
}

.btn-delete {
    background: #fc7169 !important;
    border-color: #fc7169 !important;
}

.btn-disable {
    background: rgb(216, 165, 4);
    border-color: rgb(216, 165, 4);    
}

.react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.6) !important;
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6) !important;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    z-index: 100;
}

.nav-wrapper {
    width: 100%;
    display: flex;
    border-bottom: 4px solid #222952;
    margin-top: 10px;
}

.user-filter select {
    width: 150px;
    padding: 0.1rem .75rem 0.1rem 0.75rem;
    font-size: 14px;
    height: 2rem;
}

.user-filter button  {
    padding: 2px 6px;
    border-radius: 0;
    color: #fff;
}

tr.remove td, tr.remove th {
    padding: 0 !important;
} 

tr.remove td div, tr.remove th div.custom-control {
    max-height: 0;
    min-height: 0 !important;
    padding: 0 10px;
    box-sizing: border-box;
    overflow: hidden;
    transition: max-height 0.5s, padding 0.5s, min-height 0.5s;
}

.alert-custom {
    background: #646a92;
    color: #fff;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
}
  
.custom-react-select-width {
    width: 100% !important;
}

.custom-react-select-width .custom-select__control {
    min-height: 34px;
}
  
.react-select-custom-width-common-table {
    width : 165px !important;
}

.capitalize {
    text-transform: capitalize;
}

.fixed-width-button {
    width: 150px;
}

body, .page-content-wrapper .main-panel {
    background: white;// #f9fbfd;
}

.plantitle {
    font-size: 18px;
}

@media (max-width: 980px) and (min-width : 768px) {
    .container {
        max-width: 922px;
    }
}

.Login_error {
    color: $error-color;
    font-size: 12px;
    border-radius: 2px;
    padding-left: 3px;
    padding-top: 8px;
}

.verify-btn:focus, .verify-btn:active, .verify-btn:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
}

label:not(.custom-control-label) {
    color: #858e98;
    font-size: 14px;
}

h6.donation-info {
    color: #858e98;
    font-weight: normal;
}

@media screen and (max-width: 768px){
    .page-content-wrapper .main-panel{
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
    } 
    .navBarLiPadding {
        padding-left: 0px !important;
    }
}

.trashIcon {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    cursor: pointer;
    font-size: 12px;
    color: rgb(31, 37, 51);
    margin-left: 12px;
}

.inputWithButton{
    z-index: 0 !important;
    padding-top: 4px !important;
    height: calc(2rem + 2px) ;
}

@media (max-width: 1000px) and (min-width : 768px) {
    .container .border {
        padding-top: 7px;
    } 
    .page-content-wrapper .main-panel{
        padding-right: 14px;
        padding-left: 8px;
        padding-top: 1px;
    } 
    .whenIpadView{
        padding-right: 4px;
        padding-left: 0px;
    }
    .insideCartView{
        padding-right: 10px;
        padding-left: 10px;
    }
}

.donationViewFont{
    font-size: 14px;
}

.donateButtonPadding{
    padding: 6px;
}

.donateCustomInput{
    width: 90%;
    height: 37px;
    margin-top: -2px;
    margin-left: 4px;
    font-size: 23px;
    padding: 10px 7px;
    font-weight: 600;
}

 .donationContainer {
    display: inline-flex;
 }

 .donationInput {
    margin-top: -11px;
    width: 126px;
    float: right;
 }

 .donationInput::placeholder { /* Chrome/Opera/Safari */
    font-weight: lighter;
    font-size: 14px;
    margin-top: 2px;
 }

 .backButton {
    margin-right: 0px;
    padding: 0;
    font-size: 1rem;
 }

 .payment-icon {
    height: 20px;
    float: right;
 }

 .successPagePadding {
    max-width: 795px;
}

select.form-control {
    padding: 0 0.75rem;
}

 @media (min-width : 768px) {
    .successPagePadding {
        max-width: 795px;
        margin-bottom: 69px;
    }
}

.hideWhenMobileDevice {
    display: initial;
}

@media (max-width: 600px) {
    .hideWhenMobileDevice {
        display: none;
    }
}

.textMutedCustomized{
    color: #9daab5 !important;
}

ul.dotSymbol {
    list-style-type: disc;
}

.pioneerLogo{
    height: 69px;
}

.showWhenMobile {
    display: none;
}

@media (max-width: 768px) {
    .showWhenMobile {
        display: block;
    }
}

.footer {
    a {
        text-decoration: underline; 
        font-weight: 400;
    }
}

.mr-12 {
    margin-right: 12px;
}

.cart {
    .containerBox {
        position: relative;
        display: inline-block;
        display: block;
        margin: 0 auto;
        margin-bottom: 25px;
    }
    .text-box {
        position: absolute;    
        height: 100%;
        text-align: center;    
        width: 100%;
    }
    .text-box:before {
       content: '';
       display: inline-block;
       height: 100%;
       vertical-align: middle;
    }
    h4 {
       display: inline-block;
       letter-spacing: 4px;
       padding-top: 35px;
    }
    .img-banner {
        width: 350px;
        display: block;
        max-width: 100%;
        height: auto;
        display:block;
        margin:auto;
    }
    .img-pioneer-banner {
        width: 461px;
        display: block;
        max-width: 100%;
        height: auto;
        display: block;
        margin: auto;
    }
}

.dynamic-logo {
    margin-top: -14px;
}

.forPioneerText {
    padding-top: 70px !important;
}

@media (max-width: 760px) {
    .dynamic-logo {
        margin-top: 37px;
        margin-bottom: 12px;
    }
    .cart h4 {
        padding-top: 48px;
        font-size: 20px;
    }
    .forPioneerText {
        padding-top: 52px !important;
        font-size: 14px !important;
    }
    .cart .containerBox{
        margin-bottom: 45px;
    }
}

.displayCenter {
    display: block;
    margin: 0 auto;
}

.thankyouImage{
    width: 100%;
    max-width: 533px;
}

.footerLogo {
    width: 214px;
    max-width: 100%;
    cursor: pointer;
    padding-bottom: 20px;
}

.success {
    .containerBox {
        position: relative;
        display: inline-block;
        display: block;
        margin: 0 auto;
        margin-bottom: 25px;
    }
    .text-box {
        position: absolute;    
        height: 100%;
        text-align: center;    
        width: 100%;
    }
    .text-box:before {
       content: '';
       display: inline-block;
       height: 100%;
       vertical-align: middle;
    }
    .SuccessIcon h4 {
       display: inline-block;
       letter-spacing: 4px;
       margin-top: 122px;
       font-size: 50px;
       color: black;
    }
    .img-banner {
      width: 100%;
      max-width: 533px;
      display: block;
      height: auto;
      display:block;
      margin:auto;
    }
}

@media (max-width: 760px) {
    .success .SuccessIcon h4 {
        margin-top: 93px;
        font-size: 39px;
    }
    .success .footerLogo{
        margin-top: 68px;
        width: 200px;
    }
}

@media (max-width: 400px){
    .success .SuccessIcon h4 {
        margin-top: 52px;
        font-size: 29px;
    }
}

.success {
    .border {
        border-right: none !important;
        border-left: none !important;
        background: transparent;
        box-shadow:none;
    }
}

.footerText {
    font-size: 14px;
}

.form-control, .form-group  {
    font-size: 14px !important;
}

.mb-35 {
    margin-bottom: 35px;
}

.mt-m-19 {
    margin-top: -15px;
}

.custom-select { 
    height: calc(2rem + 2px) !important;
}