@charset "UTF-8";
.payment .form-group-custom {
  flex: none;
  padding-right: 14px;
  margin-top: 20px; }

.payment .form-control {
  height: 38px; }

.payment .StripeElement  {
  padding-top: 10px !important;
  border:  1px solid #ced4da;
  border-width: 1px !important; }

.payment .StripeElement--empty  {
  padding-top: 10px !important; }

.payment .PaymentRequestButton.StripeElement {
  padding-top: 0 !important; }

.payment .StripeElement--invalid {
  border: 1px solid red; }

.payment .img-visa {
  background-image: url("https://js.stripe.com/v3/fingerprinted/img/visa-d6c6e0a636f7373e06d5fb896ad49475.svg"); }

.payment .img-mastercard {
  background-image: url("https://js.stripe.com/v3/fingerprinted/img/mastercard-a96ee3841a5e1e28d05ed3f0f4da62b8.svg"); }

.payment .img-amex {
  background-image: url("https://js.stripe.com/v3/fingerprinted/img/amex-edf6011de255d8a4c22904795c9d8770.svg"); }

.payment .img-discover {
  background-image: url("https://js.stripe.com/v3/fingerprinted/img/discover-8f3d8fc6ef836da1fcac12c095ee6fb8.svg"); }

.payment .img-diners {
  background-image: url("https://js.stripe.com/v3/fingerprinted/img/diners-fced9e136fd8c25f40a3e7b37a51dc1d.svg"); }

.payment .img-jcb {
  background-image: url("https://js.stripe.com/v3/fingerprinted/img/jcb-1b12d588a1e9465d4d9fb84a610f9136.svg"); }

.payment .pf-credit-card {
  display: none; }

.payment #brand-icon {
  position: absolute;
  top: 39px;
  right: 25px;
  width: 36px;
  height: 26px;
  /*filter: grayscale(100%);*/ }

.error-message {
  font-size: 14px;
  border-radius: 0;
  text-align: center;
  color: #fff;
  display: inline-block;
  padding: 0 4px;
  margin-bottom: 0;
  margin-top: 10px;
  background: #D9585C;
  position: relative; }

.cardValidationError {
  border: 1px solid red !important;
  background: #fff7f6 !important; }

.btn-circle.btn-lg {
  background: #75fdda;
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
  margin-top: 25px;
  margin-bottom: 24px;
  cursor: default; }

.fontWeightforH3 {
  font-weight: 700 !important; }

.MB12px {
  margin-bottom: 20px; }

.energyConnectButton {
  height: 62px !important;
  border: 3px solid lightgray !important;
  color: #0e0982 !important; }

.select-text {
  -moz-user-select: all;
  -webkit-user-select: all;
  -ms-user-select: all;
  user-select: all;
  padding-right: 15px;
  padding-left: 15px; }

.UnderlineCustom {
  text-decoration: underline; }

.totalFont {
  font-size: 20px;
  font-weight: 500; }

.totalPriceFont {
  font-size: 25px;
  font-weight: 700; }

.titleGroupContent {
  padding-left: 36px !important;
  padding-top: 46px !important; }

@media (max-width: 500px) {
  .titleGroupContent {
    padding-left: 22px !important;
    padding-top: 46px !important; } }

.includesPadding {
  padding-left: 20px !important; }

hr {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  background-color: #eaeaea;
  height: 1px; }

.firstnameIcon {
  position: absolute;
  top: 39px;
  right: 22px;
  width: 18px;
  height: 17px;
  float: right;
  opacity: 0.3; }

.number-circle {
  height: 40px;
  width: 40px;
  margin-top: 45px;
  margin-bottom: 24px; }
